<template>
    <v-container fluid class="fill-height">
        <!-- <script2 type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script2> -->

        <v-row class="d-flex align-center ">
            <v-col cols="12" md="6" class="v-col-sticky">
                <v-card rounded elevation="3" dark class="miq-drawer-main pa-6" >
                    <v-card-title class="d-flex justify-center text-center">
                        <v-row>
                            <v-col cols="12">
                                <h1 class="display-4 text-center" style="word-break: normal; font-weight: bold !important;"><strong>The #1 Add-On and Integration Platform for HubSpot</strong></h1>
                            </v-col>
                            <v-col cols="12">
                                <h3 class="font-weight-light">
                                    Smart Ramp gives you the most advanced features to triple the value of HubSpot.
                                </h3>
                            </v-col>
                            <v-col cols="12">
                                <h3 class="font-weight-medium">
                                Click the video below to learn more!
                                </h3>
                                <v-icon x-large> mdi-chevron-double-down</v-icon>
                            </v-col>
                        </v-row>
                       
                        
                    </v-card-title>
                    <v-card-text>
                        <!-- <img
                            style="width: 100%; margin: auto; display: block;"
                            class="vidyard-player-embed"
                            src="https://play.vidyard.com/3deFKovNaHs9W32x3UGVut.jpg"
                            data-uuid="3deFKovNaHs9W32x3UGVut"
                            data-v="4"
                            data-type="inline"
                            /> -->

                            
                            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                    <div class="wistia_embed wistia_async_898cwbyhik seo=true videoFoam=true" style="height:100%;position:relative;width:100%">
                                        <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                            <img src="https://fast.wistia.com/embed/medias/898cwbyhik/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </v-card-text>
                </v-card>
            </v-col>
           
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12" v-for="launchItem in launcherItems" :key="launchItem.appName">
                        <v-card elevation="5" rounded>
                            <v-card-text class="ma-1 py-0">
                                <v-row  class="d-flex align-center">
                                    <v-col cols="4" md="3" class="text-center">
                                        <v-icon :color="launchItem.isLive ? 'primary':'iq_slategray'" class="auto-limited-150">{{launchItem.appIcon}}</v-icon>
                                    </v-col>
                                    <v-divider vertical></v-divider>
                                    <v-col cols="8" md="6">
                                        <v-row no-gutters>
                                            <v-col cols="12" class="text-h5">{{launchItem.appName}}</v-col>
                                            <v-col cols="12" class="body-2">
                                                {{launchItem.appDescription}}
                                                <v-btn v-if="launchItem.appLearnMoreLink != null" text small color="primary" :to="launchItem.appLearnMoreLink"> Learn More</v-btn>
                                            </v-col>
                                            <v-btn v-if="launchItem.hasInlineHelpList" block x-large text @click.stop="launchItem.showInlineHelp = !launchItem.showInlineHelp; ">
                                                <span v-if="launchItem.appName == 'INTEGRATE IQ'">Get Started Video</span>    
                                                <span v-else>Get Started Videos</span>    
                                                
                                                <v-icon color="secondary" v-if="!launchItem.showInlineHelp"> mdi-chevron-down</v-icon>
                                                <v-icon color="secondary" v-else> mdi-chevron-up</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                    <v-divider vertical class="d-sm-none d-md-block d-none"></v-divider>
                                    <v-col offset="3" cols="6" offset-md="0" md="3"> 
                                        <v-row no-gutters class="fill-height">
                                            <v-col cols="12">
                                                <v-btn v-if="!launchItem.isLive" :loading="loadingbtn" dark block x-large style="background-color: #868E96" class="font-weight-medium" :to="launchItem.purchaseLink">   
                                                    <span>Purchase</span>    
                                                </v-btn>
                                                <v-btn v-else dark block x-large :color="launchItem.isLive ? 'primary':'accent'" class="font-weight-medium" :to="launchItem.appLink">
                                                    <span>Launch</span>    
                                                </v-btn>
    
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                                <v-fade-transition>
                                    <v-card flat v-show="launchItem.showInlineHelp">
                                        <v-card-text>
                                            <v-card elevation="0" outlined>
                                                <v-card-title>{{launchItem.appIntroTitle}}</v-card-title>
                                                <v-card-subtitle>{{launchItem.appIntroText}}</v-card-subtitle>
                                                <v-card-text>
                                                    <v-card flat>
                                                        <!-- <img
                                                            style="width: 100%; margin: auto; display: block;"
                                                            class="vidyard-player-embed ma-4"
                                                            :src="launchItem.appIntroVideoSrc"
                                                            :data-uuid="launchItem.appIntroVideoUuid"
                                                            data-v="4"
                                                            data-type="inline"
                                                        /> -->

                                                        <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                                                            <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                                                <div class="wistia_embed seo=true videoFoam=true" :class="`wistia_async_${launchItem.appIntroVideoUuid}`" style="height:100%;position:relative;width:100%">
                                                                    <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                                                        <img :src="`https://fast.wistia.com/embed/medias/${launchItem.appIntroVideoUuid}/swatch`" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </v-card>
                                                </v-card-text>
                                            </v-card>
                                            <v-card v-for="inlineHelpItem in launchItem.InlineHelpList" :key="inlineHelpItem.name" elevation="0" outlined color="iq_concrete">
                                                <v-card-text>
                                                    <v-row  class="d-flex align-center">
                                                        <v-col cols="auto" md="3" class="text-center">
                                                            <div class="d-flex flex-column justify-center align-center">
                                                                    <v-icon color="secondary" x-large>{{inlineHelpItem.icon}} </v-icon>
                                                                    <span> {{inlineHelpItem.duration}}</span>
                                                                </div>
                                                        </v-col>
                                                        <v-col cols="8" md="6">
                                                            <v-row no-gutters>
                                                                <v-col cols="12" class="text-h5">{{inlineHelpItem.name}}</v-col>
                                                                <v-col cols="12" class="body-1">{{inlineHelpItem.description}}</v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col offset="3" cols="6" offset-md="0" md="3" class="text-center">                                                     
                                                            <div  v-if="inlineHelpItem.uuid.length > 0 && inlineHelpItem.src.length > 0">
                                                                <!-- <img style="width: 100%; margin: auto; display: block;" class="vidyard-player-embed" :src="inlineHelpItem.src" :data-uuid="inlineHelpItem.uuid" data-v="4" data-type="lightbox"/> -->
                                                                <v-dialog scrollable @input="onDialogToggle">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            text 
                                                                            block
                                                                            flat
                                                                            style="min-height: auto; height: auto !important;"
                                                                        >
                                                                            <img :src="`https://fast.wistia.com/embed/medias/${inlineHelpItem.uuid}/swatch`" style="height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" />
                                                                            <v-icon color="primary" style="position: absolute; text-shadow: 0px 0px 5px #fff">mdi-play-circle</v-icon>
                                                                        </v-btn>
                                                                    </template>
    
                                                                    <v-card>
                                                                        <v-card-title class="text-h5 d-flex flex-column grey lighten-2">
                                                                            <span>{{inlineHelpItem.name}}</span>
                                                                            <small>{{inlineHelpItem.description}}</small>
                                                                        </v-card-title>
                                                                        <v-card-text style="overflow: hidden;" class="grey lighten-2">
                                                                            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                                                                                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                                                                    <div class="wistia_embed seo=true videoFoam=true" :class="`wistia_async_${inlineHelpItem.uuid}`" style="height:100%;position:relative;width:100%">
                                                                                        <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                                                                            <img :src="`https://fast.wistia.com/embed/medias/${inlineHelpItem.uuid}/swatch`" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </v-card-text>
    
    
                                                                    </v-card>
                                                                </v-dialog>
                                                            </div>
                                                            <v-dialog v-else
                                                                width="800"
                                                                >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                    color="primary"
                                                                    dark
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    block
                                                                    >
                                                                    {{inlineHelpItem.linkText}}
                                                                    </v-btn>
                                                                </template>
    
                                                                <v-card>
                                                                    <v-card-title class="text-h5 grey lighten-2">
                                                                        Schedule your 1:1 Strategy Session
                                                                    </v-card-title>
    
                                                                    <v-card-text>
                                                                    Schedule your strategy session now!
    
                                                                    <iframe :src="inlineHelpItem.link" width="auto" height="700" style="position: relative; width: 100%; height: 700px; left: 0;" frameborder="0" allowfullscreen />
                                                                    </v-card-text>
    
    
                                                                </v-card>
                                                            </v-dialog>
                                                        </v-col>
                                                    </v-row>
    
                                                </v-card-text>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>
                                </v-fade-transition>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
    
        </v-row>
    
    </v-container>
        
    
    </template>
    <script>
    import VS2 from 'vue-script2'
    import { mapGetters } from 'vuex';
    export default {
        computed:{
            ...mapGetters({
                currentCompany: 'company/getCurrentCompany',
                currentCompanyApps: 'company/getCurrentCompanyApps'
            })
        },
        data(){
            return {
                launcherItems: [
                    {
                        appName: 'INTEGRATE IQ', 
                        appIcon: '$integrateIQIcon',
                        appColor: 'primary',
                        appLink: '/cards',
                        appDescription: 'Connect any cloud software with HubSpot through our 30+ sync recipes and get launched in less than 30 days.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://fast.wistia.com/embed/medias/et8tbmeklr.jsonp',
                        appIntroVideoUuid: 'et8tbmeklr', 
                        appIntroTitle: 'Intro to IntegrateIQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: true,
                        isDemo: false,
                    },
                    {
                        appName: 'MESSAGE IQ', 
                        appIcon: '$messageIQIcon',
                        appColor: 'primary',
                        appLink: '/conversations',
                        purchaseLink: '/product/plan-selection/MessageIQ',
                        appDescription: 'Send 1:1, broadcast and automated SMS messages from inside HubSpot.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://fast.wistia.com/embed/medias/nu0hbhi9eo.jsonp',
                        appIntroVideoUuid: 'nu0hbhi9eo', 
                        appIntroTitle: ' Intro to Message IQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [
                            { name:'QUICK START',        icon:'mdi-flash',  description:'Follow our quick start guide and immediately impact your business.', linkText: '', link: '', duration:'2 minutes', src:'https://fast.wistia.com/embed/medias/mj6e9pzsfu.jsonp', uuid:'mj6e9pzsfu'},
                            { name:'TAKE IF FOR A SPIN', icon:'mdi-steering',  description:'Orient yourself with this quick tour of the place.', linkText: '', link: '', duration:'2 minutes', src:'https://fast.wistia.com/embed/medias/9da01w1vsr.jsonp', uuid:'9da01w1vsr'},
                            { name:'DIVE DEEP INTO THE HOW TO\’S', icon:'mdi-steering',  description:'Learn how to use the full power of the 4 C’s with this video playlist.', linkText: '', link: '', duration:'2 minutes', src:'https://fast.wistia.com/embed/medias/0ojptq5cjk.jsonp', uuid:'0ojptq5cjk'},
                            { name:'LEARN FROM THE IQ PLAYBOOK', icon:'mdi-steering',  description:'Elevate your IQ interactions with live video training of best practices.', linkText: '', link: '', duration:'2 minutes', src:'https://fast.wistia.com/embed/medias/4wmwaekacr.jsonp', uuid:'4wmwaekacr'},
                            { name:'SCHEDULE YOUR 1:1 STRATEGY SESSION', icon:'mdi-calendar-cursor',  description:'Book time now to get your personalized IQ plan.', linkText: 'Schedule', link: 'https://meetings.hubspot.com/tim1303/miq-strategy-session?embed=true', duration:'2 minutes', src:'', uuid:''}
                        ],
                    },
                    {
                        appName: 'STATUS IQ',     
                        appIcon: '$statusIQIcon',
                        appColor: 'iq_slategray',
                        appLink: '/statusiq',
                        purchaseLink: '/product/plan-selection/StatusIQ',
                        appDescription: 'Empower your customers to access their current status of anything found in a HubSpot dropdown property or pipeline.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://fast.wistia.com/embed/medias/0vfnllckyf.jsonp',
                        appIntroVideoUuid: '0vfnllckyf', 
                        appIntroTitle: 'Intro to Status IQ',
                        appIntroText:'Learn how simple it is to create and use a Status IQ tracker.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [],
                    },
                    {
                        appName: 'PRICE IQ', 
                        appIcon: '$priceIQIcon',
                        appColor: 'iq_slategray',
                        appLink: '/priceiq',
                        purchaseLink: '/product/plan-selection/PriceIQ',
                        appDescription: 'Automatically update line item pricing based on company price lists, lookup and manage price lists, all from inside HubSpot.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://fast.wistia.com/embed/medias/bgq76n78br.jsonp',
                        appIntroVideoUuid: 'bgq76n78br', 
                        appIntroTitle: 'Intro to Price IQ',
                        appIntroText:'Learn how to create multiple price lists and apply custom pricing to HubSpot Deals.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [],
                    },
                    {
                        appName: 'STOCK IQ', 
                        appIcon: '$stockIQicon',
                        appColor: 'iq_slategray',
                        appLink: '/stockiq',
                        purchaseLink: '/product/plan-selection/StockIQ',
                        appDescription: 'Search inventory levels, verify line items are in stock before you tell the customer... all from inside HubSpot.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://fast.wistia.com/embed/medias/zsqxr54ldv.jsonp',
                        appIntroVideoUuid: 'zsqxr54ldv', 
                        appIntroTitle: 'Intro to Stock IQ',
                        appIntroText:'Watch how to connect HubSpot to your ERP then check for current inventory from HubSpot Deals.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [],
                    }
                ],
                loadingbtn: false,
                videoIds: ['898cwbyhik',],
                players: [],

            }
        },
        created() {
            this.loadingbtn = true;
            this.$auth.auth0ClientPromise.then(async () => {
                if(Object.keys(this.currentCompany).length === 0){
                    await this.$store.dispatch('getUserCompany');
                    this.parseCompanyApps();
                    this.loadingbtn = false;
                }
            });

        },
        mounted() {
            if(Object.keys(this.currentCompany).length !== 0) {
                this.parseCompanyApps();
                this.loadingbtn = false;
            } 

            const wistiaScriptBase = document.createElement('script');
            wistiaScriptBase.src = 'https://fast.wistia.com/assets/external/E-v1.js';
            wistiaScriptBase.async = true;
            document.head.appendChild(wistiaScriptBase);
           

            this.launcherItems.forEach(item => {
                if (item.appIntroVideoUuid) {
                    const wistiaScript = document.createElement('script');
                    wistiaScript.src = `https://fast.wistia.com/embed/medias/${item.appIntroVideoUuid}.jsonp`;
                    wistiaScript.async = true;
                    document.head.appendChild(wistiaScript);
                    this.videoIds.push(item.appIntroVideoUuid);

                }
                if (item.InlineHelpList) {
                    item.InlineHelpList.forEach(inlineHelpItem => {
                        if (inlineHelpItem.uuid) {
                            console.log(inlineHelpItem.uuid);
                            const wistiaScript = document.createElement('script');
                            wistiaScript.src = `https://fast.wistia.com/embed/medias/${inlineHelpItem.uuid}.jsonp`;
                            wistiaScript.async = true;
                            document.head.appendChild(wistiaScript);
                            this.videoIds.push(inlineHelpItem.uuid);
                        }
                    });
                }
            });
            
            this.initializePlayers();

            // const notificationOptions = {
            //     title: "New Message",
            //     body: "You have a new message! that Could get pretty long long log long godooafdusfuafosd foasfu oasufa",
            //     icon: "../assets/images/logo-color-lg.png",
            //     badge: "../assets/images/logo-sm.png",
            //     image: "../assets/images/company-info.png",
            //     tag: "message"+Math.random,
            //     requireInteraction: false,
            //     actions: [{ action: "open", title: "Open" }],
            //     timestamp: Date.now(),
            //     renotify: true
            // };
            // setTimeout(() => {
            //     this.showNotification(notificationOptions)
            // }, 10000);

            // setTimeout(() => {
            //     this.showNotification(notificationOptions)
            // }, 20000);

            // setTimeout(() => {
            //     this.showNotification(notificationOptions)
            // }, 30000);


        },
        methods: {
            parseCompanyApps(){
                if(this.currentCompanyApps.integrations){
                    this.launcherItems[0].isLive = true;
                }
                if(this.currentCompanyApps.messageIq){
                    this.launcherItems[1].isLive = true;
                }
                if(this.currentCompanyApps.statusIq){
                    this.launcherItems[2].isLive = true;
                }
                if(this.currentCompanyApps.priceIq){
                    this.launcherItems[3].isLive = true;
                }
                if(this.currentCompanyApps.stockIq){
                    this.launcherItems[4].isLive = true;
                }
            },
            initializePlayers() {
                // Create Wistia players and store references
                this.videoIds.forEach((videoId) => {
                    window._wq = window._wq || [];
                    window._wq.push({
                    id: videoId,
                    onReady: (video) => {
                        // Store each video player in the players array
                        this.players.push(video);

                        // Listen to play events to pause other videos
                        video.bind('play', () => {
                            this.pauseOtherVideos(video);
                        });
                    },
                    });
                });
            },
            pauseOtherVideos(currentVideo) {
                // Pause all other videos except the one that just started playing
                this.players.forEach((video) => {
                    if (video !== currentVideo) {
                        video.pause();
                    }
                });
            },
            onDialogToggle(isOpen) {
                if (isOpen) {
                    this.onDialogOpen();
                }else{
                    this.onDialogClose();
                }
            },
            onDialogOpen() {
                // Your custom function logic here
                this.pauseOtherVideos();
            },
            onDialogClose() {
                // Your custom function logic here
                this.pauseOtherVideos();
            }
            
        }
    }
    </script>
    <style lang="scss">
        .v-col-sticky{
            position: sticky;
            top: 0;
            bottom: 0;
        }
        .v-dialog{
            .wistia_responsive_wrapper{
                max-height: 75vh !important;
                .w-chrome {
                    > div{
                        max-height: 75vh !important;
                    }
                }
            }
        }
       
      
    </style>